const passwordField = document.getElementById('password');
const passwordConfirmField = document.getElementById('password-confirm');
const togglePassword = document.getElementById('togglePassword');
const togglePassword2 = document.getElementById('togglePassword2');
if (togglePassword !== null){
togglePassword.addEventListener('click', function() {
  if (passwordField.type === 'password') {
    passwordField.type = 'text';
}else{
      passwordField.type = 'password';

  }
});
}
if (togglePassword2 !== null){
togglePassword2.addEventListener('click', function() {
  if (passwordConfirmField.type === 'password') {
    passwordConfirmField.type = 'text';
}else{
      passwordConfirmField.type = 'password';

  }
});
}
var checkbox = document.getElementById("myCheckbox");
if (checkbox !== null){
var targetElement = document.getElementById("myCheckbox");

checkbox.addEventListener("click", function() {

targetElement.classList.toggle("checked");
});
}
$('#closebtn').hide();
var openbtn = document.getElementById("openbtn");
openbtn.addEventListener("click", function() {
    $('#openbtn').hide();
    $('#closebtn').show();
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("app").style.marginLeft = "250px";
    // $('#mySidebar').show();
});

var closebtn = document.getElementById("closebtn");
closebtn.addEventListener("click", function() {
    $('#openbtn').show();
    $('#closebtn').hide();
    // $('#mySidebar').hide();
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("app").style.marginLeft= "0";

});


$(document).ready(function(){
    $('[data-bs-toggle="tooltip"]').tooltip();
});
